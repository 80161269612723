<template>
  <div>
    <helloWorld />
    <div class="content-1180">
      <div style="display:flex;flex-wrap:wrap">
        <img class="left-img" :src="assets" alt="">
        <div style="margin-left:20px;max-width:62%">
          <p class="detailF-title">{{title}}</p>
          <p style="display:flex;align-items:center;margin-top:10px">
            <span style="color: rgba(0, 0, 0, 0.65);font-size: 14px;">{{author}}</span>
            <span style="margin-left:30px;color: rgba(0, 0, 0, 0.65);font-size: 14px;display:flex;align-items:center">
              <img style="width:30px" src="@/assets/give.png" alt="">
              <span>63</span>
            </span>
            <span style="margin-left:30px;color: rgba(0, 0, 0, 0.65);font-size: 14px;display:flex;align-items:center">
              <img style="width:30px" src="@/assets/views.png" alt="">
              <span>15</span>
            </span>
            <span style="margin-left:30px;color: rgba(0, 0, 0, 0.65);font-size: 12px;display:flex;align-items:center">
              <i class="el-icon-star-on" style="color:#999;font-size:16px"></i>
              <span style="margin-left:2px">收藏</span>
            </span>
          </p>
          <div style="text-align:left;margin:20px 0 0 0">
            <el-button type="primary" size="small" style="background:#e31629; border:none">点击下载</el-button>
          </div>
          <div class="contentWrap4">
            <div class="makeMsgTitle">
              <div>
                介绍
              </div>
            </div>
            <div class="makeMsgContent">
              无聊猿——Bored Ape Yacht Club (BAYC)——已成为最昂贵和最具影响力的 NFT 收藏品系列。BAYC 已经从简单的 JPEG NFT 演变成一个更广泛的生态系统，该生态系统正变得与 Supreme 等品牌一样具有文化影响力。它们的迅速崛起实际上是主流时尚品牌和基于加密的项目都闻所未闻和无与伦比的。虽然到目前为止他们所取得的一切都令人印象深刻，但至少可以说，这可能只是他们将要创造的更大宇宙的开始。持有人的活动表明，degens 和 smart money 都对 BAYC 的未来持相似的乐观态度。团队梦想着一个由 BAYC 统治的世界，这个梦想可能并不牵强，BAYC 可能会成为元宇宙的统治者。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  data() {
    return {
      author: '',
      title: '',
      assets: ''
    }
  },
  mounted() {
    this.author = this.$route.query.author
    this.title = this.$route.query.title
    this.assets = this.$route.query.assets
  },
  methods: {
    todetail() {
      this.$router.push('./freeDetail')
    }
  }
}
</script>
<style scoped>
.content-1180 {
  max-width: 1180px;
  margin:auto;
  padding: 40px 0;
}
.left-img {
  width: 35%;
  height: 380px;
}
.detailF-title {
  font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
}
.contentWrap4 {
    width: 100%;
    float: left;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    margin-top: 20px;
}
.makeMsgTitle {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background: #f8f8f8;
    padding: 11px 0 11px 15px;
    border-radius: 10px 10px 0 0;
}
.makeMsgTitle div {
    /* margin-left: 11px; */
    color: #333;
    font-size: 14px;
    /* font-weight: 600; */
}
.makeMsgContent {
    padding-top: 13px;
    max-width: 100%;
    padding: 20px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8c8c;
    letter-spacing: 1px;
    line-height: 18px;
}
</style>